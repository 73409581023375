const translation = {
    translation: {
        "cardiffbank": "CardiffBank",
        "cardiffbank_description": "Somos un banco sostenible que transforman positivamente las finanzas sus clientes.",
        "lang_es": "Español",
        "lang_en": "Inglés",
        "address_location": "3 Wood St, Cardiff CF10 1EN, Reino Unido",
        "address": "Dirección",
        "email": "Correo",
        "email_advice": "advice@cardiff-bank.com",
        "email_support": "legal@cardiff-bank.com",
        "phone_line": "442080895187",
        "search_placeholder": "Buscar...",
        "virtual branch people": "Sucursal Virtual Personas",
        "go to virtual branch people": "Ir a la sucursal Virtual",
        "services": "Servicios",
        "i need an account": "Necesito una cuenta",
        "i want to invest": "Quiero invertir",
        "i want an insurance": "Quiero un seguro",
        "I want to make an international wire transfer": "Giros internacionales",
        "I need advice": "Necesito asesoria",
        "information": "Información",
        "contact": "Contáctanos",
        "about us": "Nosotros",
        "view more": "Ver más",
        "chief executive officer name": "Hamza Shatela",
        "chief executive officer": "Presidente Ejecutivo",
        "hire": "Contratar",
        "see eligibility and limitations": "Ver términos y condiciones",
        "financial consumer services": "Servicios al consumidor financiero",
        "financial consumer services small description": "Conoce todas las soluciones sostenibles que tenemos para ti.",
        "digital channels": "Canales digitales",
        "digital channels small description": "Ahorra tiempo con nuestros canales digitales",
        "open an account": "Abrir cuenta",
        "visit us": "Visítanos",
        "visit us large": "Visítanos en cualquiera de nuestras sucursales",
        "visit us small description": "Nuestros asesores están disponibles para ayudarte",
        "blog": "Blog Salud Financiera",
        "blog small description": "Mejora tu salud financiera y disfruta de la tranquilidad mental que viene con ella.",
        "previous": "Anterior",
        "next": "Siguiente",
        "callToAction-1": "Únete ahora a la familia Cardiff",
        "callToAction-1 description": "Abre tu cuenta gratis en solo unos minutos.",
        "useful links": "Información de interés",
        "financial health": "Salud Financiera",
        "twitter": "X",
        "news cardiffbank": "Noticas CardiffBank",
        "subscribe": "Suscríbete",
        "subscribe calltoaction": "¡Suscribete ahora!",
        "send": "Enviar",
        "opening hours": "Horario de atención",
        "schedule your advice": "Agenda tu asesoría",
        "schedule": "Agendar",
        "do you need financial advice?": "¿Necesitas asesoría financiera?",
        "do you need financial advice? calltoaction": "No esperes más para obtener asesoramiento gratuito. Nuestros asesores están listos para apoyarte.",
        "account types": "Cuentas CardiffBank",
        "apply here": "Solicítala aquí",
        "not available for this region": "No está disponible en esta región",
        "Investment funds": "Fondos de Inversión",
        "international wire transfer": "Giros Internacionales",
        "full name": "Nombre completo",
        "phone": "Teléfono",
        "subject": "Asunto",
        "message": "mensaje",
        "conctat title": "Queremos brindarte la mejor asesoría.",
        "conctat small description": "Entendemos que cada persona es única y por eso queremos brindarte la mejor asesoría personalizada para cumplir tus metas y objetivos.",
        "success form title subscribe": "¡Gracias por unirte a la familia CardiffBank!",
        "error form description subscribe": "Pero parece que ha habido un problema al enviar tu solicitud. Por favor, verifica que tu correo es correcto y vuelve a intentarlo.",
        "success form title": "¡Gracias por ponerte en contacto con nosotros!",
        "success form description": "Hemos recibido tu mensaje y nos pondremos en contacto contigo lo antes posible.",
        "error form title": "Lo sentimos",
        "error form description": "Pero parece que ha habido un problema al enviar tu formulario de contacto. Por favor, verifica que todos los campos requeridos estén completos y vuelve a intentarlo. ",
        "enter information call to action": "Necesitamos validar tu identidad. Por favor, ingresa los siguientes datos.",
        "enter basic information": "Ingrese su información básica",
        "basic information": "Información básica",
        "name": "Nombre",
        "first name": "Nombre",
        "last name": "Apellido",
        "i agree to the": "Acepto los",
        "terms & conditions": "términos & condiciones",
        "and": "y",
        "privacy policy": "política de privacidad.",
        "personal info": "Información personal",
        "enter personal information": "Ingrese su información personal",
        "country": "País",
        "state": "Región",
        "city": "Ciudad",
        "account detail": "Detalle de cuenta",
        "account type": "Tipo de cuenta",
        "verify identity": "Verificación de identidad",
        "date of birth": "Fecha de nacimiento",
        "dni": "DNI",
        "dni small description": "Ingrese su DNI si es usted persona natural o su NIF si es una empresa o una sociedad de responsabilidad limitada con un único propietario.",
        "birthplace": "Lugar de nacimiento",

        "suscription check": "Acepto recibir información de CardiffBank por vía electrónica en la dirección de correo electrónico que haya facilitado.",
        "this is a required field": "Este campo es obligatorio",
        "must be a valid email": "Correo invalido",
        "must be a valid number": "Número invalido",
        "must be a valid date": "Fecha invalida",
        "select an option": "Seleccione una opción",
        "terms and conditions validation": "Debe aceptar las políticas de Cardiffbank para enviar la solicitud del producto",
        "zip": "Zip",
        "request": "Solicitud",

        "request success message": "Gracias por unirte a la familia [cardiffbank] nuestros asesores están analizando tu solicitud y se comunicarán con usted lo antes posible, para hacer seguimiento a su solicitud puede enviarnos un correo a [support_email] indicado el número de radicado #[registered_id] o llamando a línea [phone_number], estamos atentos a cualquier inquietud.",

        "registration number": "radicado",
        "user": "Usuario",
        "pass": "Contraseña",
        "pass repeat": "Repetir contraseña",
        "login": "Login",
        "length not allowed": "Longitud no permitida",
        "error login": "Usuario o contraseña incorreta",
        "error login block": "Has superado el número máximo de intentos permitidos. Por seguridad, tu cuenta ha sido bloqueada temporalmente. Intenta de nuevo en 1 hora. Si necesitas asistencia, contacta a soporte técnico.",
        "view balance": "Ver saldo",
        "hi": "Hola",
        "products": "Productos",
        "balance": "Saldo disponible",
        "last payment": "Última transacción",
        "balance call to action": "Si no tienes ningún producto suscrito aun, comunícate con nuestros asesores a la línea [phone_number] o ingresa a ",
        "average profit": 'Rentabilidad promedio',
        "accrued profit": 'Rentabilidad acumulada',
        "transactions": 'Movimientos',
        "recent activity": 'Actividad reciente',
        "payment profitability": "Pago rendimientos",
        "initdeposit": "Depósito",
        "credit": "Crédito",
        "deposit": "Depósito",
        "deposits": "Depósitos",
        "View all activity": "Cargar más movimientos",
        "View all products": "Cargar más productos",
        "logout": "Cerrar sesión",
        "account number": "Número de cuenta",
        "account": "Cuenta",
        "accounts": "Cuentas",
        "active": "Activos",
        "review": "En revisión",
        "aprove": "Aprovar",
        "refuse": "Rechazar",
        "cancel": "Cancelar",
        "receipt number": "Número de comprobante",
        "registration number title": "Número de radicado",
        "detail": "Detalle",
        "active account": "Activar cuenta",
        "active account small description": "El usuario requiere activar la cuenta para continuar.",
        "date": "Fecha",
        "amount": "Monto",
        "product": "Producto",
        "status": "Estado",
        "account status": "Estado de la cuenta",
        "account-active": "Activa",
        "account-review": "En revisión",
        "deposit-active": "Activa",
        "deposit-review": "En revisión",
        "initial amount": 'Monto inicial',
        "request amount": 'Monto solicitado',
        "operation number": 'Número de operación',
        "add deposit": "Crear depósito",
        "add deposit to new user": "Crear depósito a un nuevo usuario",
        "create account": "Crear cuenta",
        "unable content": "Contenido no disponible",
        "remove deposit": "Eliminar depósito",
        "close": "Cerrar",
        "remove account": "Eliminar cuenta",
        "unactive account": "Desactivar cuenta",
        "select an status": "Seleccione un estado",
        "select an user": "Seleccione una cuenta",
        "transfers": "Transferencias",
        "register products": "Registrar cuentas",
        "registered products": "Cuentas registradas",
        "nikname": "Alias",
        "recipient name": "Nombre beneficiario",
        "type code": "Tipo código",
        "code": "Código",
        "recipient bank": "Banco beneficiario",
        "Value to be transferred": "Valor a transferir",
        "transfer money": "Transferir dinero",
        "transfer money small description": "Si no ves alguno de tus productos, puede estar oculto.",
        "unable product": 'Producto no disponible.',
        "insufficient funds": 'Fondos insuficientes.',
        "pending": 'Pendiente',
        "credit cardiffbank": 'Crédito CardiffBank',
        "product detail": 'Detalle de producto',
        "credit request": 'Solicitar crédito',
        "privacy policy title": "Política de privacidad",
        "the general data protection regulation": "Política de Tratamiento de Datos Personales",
        "cookie policy": "Política de cookies",
        "exceeded value": "El valor excede el limite permitido de ",
        "forget password": "Recuperar contraseña",
        "forgot password title": "¿Olvidó su contraseña?",
        "forgot password small description": "Introduzca la dirección de correo electrónico asociada a su cuenta y le enviaremos un enlace para restablecer su contraseña.",
        "password validation": "Su contraseña debe tener 6 dígitos.",
        "password validation repeat": "Contraseña no coincide",
        "deposit type": "Tipo de deposito",
        "withdrawal": "Retiro",
    }
}

export default translation;